import { Seo, Text } from 'components';
import Footer from 'components/Footer';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Header from 'components/Header';
import { useRouter } from 'apis/history';
import { mobile, useQuery } from 'styles/breakpoints';
import CtaLogo from '../../assets/images/cta.png';
import Overlay from 'pages/start/quiz/components/Overlay';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './paginationStyle.css';
import data from './cases';
import SwiperButtons from './SwiperButton';
import { Navigation, Pagination } from 'swiper';

const MainContainer = styled.div`
  width: 100vw;
  background-size: cover;
`;

const NavRightContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 4.5rem;
  align-items: center;

  @media ${mobile} {
    grid-template-columns: auto;
  }
`;

const NavRightLinksContainer = styled.div`
  display: flex;
  align-items: center;
  @media ${mobile} {
    display: none;
  }
`;

const NavRightStoresContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 1.5rem;
  align-items: center;
  .active-menu {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    backdrop-filter: blur(24px);
  }
  .menu-trigger {
    position: absolute;
    top: 10px;
    width: 48px;
    height: 48px;
    padding: 17px 16px;
    right: 4px;
    z-index: 100;
    cursor: pointer;
    display: block;

    span {
      display: block;
      margin-bottom: 4px;
      transition: all 0.2s ease;
      height: 2px;
      border-radius: 5px;
      background: #1c1c28;
      &:nth-child(1) {
        width: 50%;
        margin-left: 50%;
      }
      &:nth-child(3) {
        width: 75%;
        margin-left: auto;
      }
      .active-menu &:nth-child(2) {
        opacity: 0;
      }
      .active-menu &:nth-child(1) {
        transform: rotate(-45deg);
        margin: 7px 0 -8px;
        width: 100%;
        border-radius: 0;
      }
      .active-menu &:nth-child(3) {
        transform: rotate(45deg);
        border-radius: 0;
        width: 100%;
      }
    }
  }
`;

const StyledContainer = styled.div`
  cursor: pointer;
  margin: 0 1.5rem;
  white-space: nowrap;
`;

const Cases = () => {
  const [show, setShow] = useState<boolean>(false);

  const { isMobile } = useQuery();

  const { goToContact, goToStory, goToReviews, goToFAQ, goToStarterGuide } =
    useRouter();

  const pagination = {
    clickable: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
  };

  const renderRightComponent = () => (
    <NavRightContainer>
      <NavRightLinksContainer>
        <StyledContainer onClick={() => goToStory()}>
          <Text type="body500">Our Story</Text>
        </StyledContainer>
        <StyledContainer onClick={() => goToReviews()}>
          <Text type="body500">Reviews</Text>
        </StyledContainer>
        <StyledContainer onClick={() => goToFAQ()}>
          <Text type="body500">FAQ</Text>
        </StyledContainer>
        <StyledContainer onClick={() => goToStarterGuide()}>
          <Text type="body500">Starter Guide</Text>
        </StyledContainer>
        <StyledContainer onClick={() => goToContact()}>
          <Text type="body500">Contact Us</Text>
        </StyledContainer>
      </NavRightLinksContainer>
      <NavRightStoresContainer>
        {isMobile && (
          <div
            className={`menu-trigger`}
            onClick={() => setShow(prev => !prev)}
          >
            <span />
            <span />
            <span />
          </div>
        )}
      </NavRightStoresContainer>
    </NavRightContainer>
  );
  return (
    <>
      <Seo />
      <MainContainer className={show ? 'active-menu' : ''}>
        <Header
          logoVariant="left"
          sticky={true}
          RightComponent={renderRightComponent()}
          color="light0"
        />
      </MainContainer>

      {show && (
        <Overlay
          zIndex={2}
          navLinks={[
            { title: 'Our Story', url: 'https://effectoapp.com/story' },
            { title: 'Reviews', url: 'https://effectoapp.com/reviews' },
            { title: 'FAQ', url: 'https://effectoapp.com/faq' },
            { title: 'Starter Guide', url: 'https://effectoapp.com/single' },
            { title: 'Contact Us', url: 'https://effectoapp.com/contact' },
          ]}
          socialLinks={[
            { svgId: 'f-reddit', url: 'https://www.reddit.com/r/EffectoApp/' },
            { svgId: 'f-tw', url: 'https://twitter.com/EffectoApp' },
            { svgId: 'f-fb', url: 'https://www.facebook.com/TheEffectoApp' },
            {
              svgId: 'f-instagram',
              url: 'https://www.instagram.com/effectoapp.com/',
            },
          ]}
        />
      )}
      <TitleContainer>
        {isMobile ? (
          <TitleWrap>
            <Title>
              Find what really <StyledStrong>improves</StyledStrong> or{' '}
              <StyledStrong>worsens</StyledStrong> your health
            </Title>
            <SubTitle>Success stories shared by our users</SubTitle>
          </TitleWrap>
        ) : (
          <TitleWrap>
            <Title>
              Find what really <StyledStrong>improves</StyledStrong> or
            </Title>
            <br />
            <Title>
              <StyledStrong>worsens</StyledStrong> your health
            </Title>
            <SubTitle>Success stories shared by our users</SubTitle>
          </TitleWrap>
        )}
      </TitleContainer>
      <SwiperContainer>
        <SwiperWrapper>
          <SwiperTop>
            <StyledSwiper
              spaceBetween={100}
              modules={[Navigation, Pagination]}
              pagination={pagination}
              loop={true}
            >
              {data.data.map((item, indx) => (
                <SwiperSlide key={indx}>
                  <CardItemContainer>
                    <CardItem>
                      <CardItemLabel>{item.cardTitle}</CardItemLabel>
                      {item.sections.map(section => (
                        <>
                          <CardItemSection>
                            <CardItemIcon>
                              {section.image ? (
                                <img src={section.image} alt="" />
                              ) : (
                                <EmojiDiv>{section.icon}</EmojiDiv>
                              )}
                            </CardItemIcon>
                            <CardItemTitle>{section.title}</CardItemTitle>
                            {section.greenDiff ? (
                              <GreenDiff>{section.greenDiff}</GreenDiff>
                            ) : (
                              <RedDiff>{section.redDiff}</RedDiff>
                            )}
                            <Bar>
                              {section.greenDiff ? (
                                <GreenBar width={section.width} />
                              ) : (
                                <RedBar width={section.width} />
                              )}
                            </Bar>
                          </CardItemSection>
                        </>
                      ))}
                    </CardItem>
                    <CardItemText>
                      <div>
                        <CardItemTextTitle>{item.title}</CardItemTextTitle>
                        <CardItemTextPar>{item.subTitle}</CardItemTextPar>
                      </div>

                      <div>
                        <CardItemSubTitle>{item.subTextTitle}</CardItemSubTitle>
                        <CardItemSubPar>{item.subTextPar}</CardItemSubPar>
                      </div>
                    </CardItemText>
                  </CardItemContainer>
                </SwiperSlide>
              ))}
              <SwiperBottom>
                <Anonymous href="https://effectoapp.com/contact">
                  Submit anonymously
                </Anonymous>
                <SwiperButtons />
              </SwiperBottom>
            </StyledSwiper>
          </SwiperTop>
        </SwiperWrapper>
      </SwiperContainer>

      <CtaContainer>
        <CtaWrapper>
          <CtaBox>
            <CtaText>
              <CtaTitle>Effecto Starter Guide</CtaTitle>
              <CtaSubTitle>
                We understand that starting something new can be overwhelming
                but don’t worry. Here are several tips for a simple and
                effective start with Effecto.
              </CtaSubTitle>
              <CtaButton onClick={() => goToStarterGuide()}>
                See full post
              </CtaButton>
            </CtaText>
            <CtaImage>
              <CtaLogoW src={CtaLogo} />
            </CtaImage>
          </CtaBox>
        </CtaWrapper>
      </CtaContainer>
      <Footer />
    </>
  );
};

export default Cases;

const Anonymous = styled.a`
  font-family: 'Poppins';
  cursor: pointer;
  text-decoration: underline !important;
`;

const EmojiDiv = styled.div`
  align-items: center;
  background: #8f90a6;
  border-radius: 2px;
  display: flex;
  font-size: 22px;
  height: 38px;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 38px;
  @media ${mobile} {
    height: 28px;
    width: 28px;
  }
`;

const CardItemSubPar = styled(Text)`
  font-family: 'Poppins';
  color: #c7c9d9;
  font-size: 16px;
  letter-spacing: -0.024em;
  line-height: 140%;
  margin-top: auto;
  @media ${mobile} {
    font-size: 14px;
  }
`;

const CardItemSubTitle = styled(Text)`
  font-family: 'Poppins';
  color: #fff;
  display: block;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.036em;
  line-height: 140%;
  margin-bottom: 4px;
  margin-top: 12px;
`;

const CardItemTextPar = styled(Text)`
  color: #e4e4eb;
  font-size: 18px;
  letter-spacing: -0.024em;
  font-family: 'Poppins';
  line-height: 150%;
  @media ${mobile} {
    width: 359px;
    font-size: 1rem;
  }
`;

const CardItemTextTitle = styled(Text)`
  color: #fff;
  font-size: 1.75rem;
  font-family: 'Poppins';
  margin-bottom: 14px;

  @media ${mobile} {
    font-size: 24px;
    line-height: 140%;
    padding: 18px 0;
    width: 358px;
  }
`;

const CardItemText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 570px;
  @media ${mobile} {
    height: 270px;
  }
`;

const CardItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  @media ${mobile} {
    flex-direction: column;
  }
`;

const StyledSwiper = styled(Swiper)`
  margin: 3rem 0 2rem 0;
`;

const RedBar = styled.div<{ width: string }>`
  transform: scale(-1);
  transform-origin: 0 50%;
  border-radius: 4px;
  height: 100%;
  left: 50%;
  position: absolute;
  top: 0;
  width: ${props => props.width}%;
  background: #ff3b3b;
`;

const GreenBar = styled.div<{ width: string }>`
  border-radius: 4px;
  height: 100%;
  left: 50%;
  position: absolute;
  top: 0;
  width: ${props => props.width}%;
  background: #06c270;
`;

const Bar = styled.div`
  background: #8f90a6;
  border-radius: 4px;
  display: flex;
  height: 6px;
  justify-content: center;
  position: relative;
  @media ${mobile} {
    height: 4px;
  }
`;

const GreenDiff = styled.div`
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  color: #06c270;
  font-size: 1rem;
  font-family: 'Poppins';
  @media ${mobile} {
    font-size: 0.788rem;
  }
`;

const RedDiff = styled.div`
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  color: #ff3b3b;
  font-size: 1rem;
  font-family: 'Poppins';
  @media ${mobile} {
    font-size: 0.788rem;
  }
`;

const CardItemTitle = styled.div`
  font-family: 'Poppins';
  font-size: 1rem;
  letter-spacing: -0.024em;
  line-height: 140%;
  margin-bottom: 12px;
  padding-right: 60px;
  @media ${mobile} {
    font-size: 0.725rem;
    margin-bottom: 10px;
  }
`;

const CardItemIcon = styled.div`
  align-items: center;
  background: #8f90a6;
  border-radius: 2px;
  display: flex;
  font-size: 22px;
  height: 38px;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 38px;
  @media ${mobile} {
    height: 28px;
    width: 28px;
  }
`;

const CardItemSection = styled.div`
  margin-bottom: 34px;
  padding-left: 54px;
  position: relative;
  @media ${mobile} {
    margin-bottom: 23px;
    padding-left: 40px;
  }
`;

const CardItemLabel = styled.div`
  font-size: 1rem;
  font-family: 'Poppins';
  font-weight: 400;
  letter-spacing: 0;
  line-height: 140%;
  margin-bottom: 31px;
  @media ${mobile} {
    font-size: 0.75rem;
    margin-bottom: 20px;
  }
`;

const CardItem = styled.div`
  background: #555770;
  border: 1px solid #8f90a6;
  border-radius: 16px;
  box-shadow: 0 4px 32px rgba(28, 28, 40, 0.06);
  color: #fff;
  margin-left: 10px;
  min-height: 489px;
  padding: 14px 18px 24px;
  width: 412px;
  @media ${mobile} {
    margin-left: unset;
    width: 100%;
    min-height: 0;
  }
`;

const CtaLogoW = styled.img`
  @media ${mobile} {
    object-fit: contain;
    width: 100%;
  }
`;

const CtaButton = styled.div`
  align-items: center;
  background: #1c1c28;
  border: 0;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  letter-spacing: -0.024em;
  min-height: 48px;
  min-width: 240px;
  padding: 14px 16px;
  text-align: center;
  transition: all 0.3s ease;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  min-width: 0;
  padding-left: 24px;
  padding-right: 24px;
  cursor: pointer;
`;

const CtaImage = styled.div`
  align-items: flex-end;
  background: #ffce98;
  display: flex;
  margin-left: auto;
  position: relative;
  width: 48%;
  @media ${mobile} {
    width: 100%;
  }
`;

const CtaSubTitle = styled(Text)`
  margin-bottom: 30px;
  font-family: 'Poppins';
  font-weight: 300;
  letter-spacing: -0.024em;
`;

const CtaTitle = styled(Text)`
  color: #1c1c28;
  margin-bottom: 16px;
  letter-spacing: -0.036em;
  line-height: 140%;
  font-size: 32px;
  font-weight: 500;
  font-family: 'Poppins';
  @media ${mobile} {
    font-size: 28px;
    line-height: 140%;
  }
`;

const CtaText = styled.div`
  margin: auto 0;
  padding: 48px;
  width: 50%;
  @media ${mobile} {
    margin: 0;
    padding: 24px 16px;
    width: 100%;
  }
`;

const CtaBox = styled.div`
  border: 1px solid #e4e4eb;
  border-radius: 16px;
  display: flex;
  overflow: hidden;
  @media ${mobile} {
    flex-direction: column;
  }
`;

const CtaWrapper = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 0 32px;
  width: 1204px;
  @media ${mobile} {
    padding: 0 16px;
  }
`;

const CtaContainer = styled.div`
  color: #28293d;
  font-size: 18px;
  letter-spacing: -0.024em;
  line-height: 150%;
  padding: 72px 0;
  @media ${mobile} {
    padding: 48px 0;
  }
`;

const SwiperBottom = styled.div`
  align-items: center;
  display: flex;
  margin: 48px 0;
  position: relative;
  justify-content: space-between;
`;

const SwiperTop = styled.div``;

const SwiperWrapper = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 0 32px;
  width: 1204px;
  @media ${mobile} {
    padding: 18px 16px;
  }
`;

const SwiperContainer = styled.div`
  background: #1c1c28;
  color: #fff;
  padding: 64px 0 16px;
  @media ${mobile} {
    padding: unset;
  }
`;

const StyledStrong = styled.strong`
  font-weight: 500;
`;

const SubTitle = styled(Text)`
  color: #1c1c28;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: -0.024em;
  line-height: 150%;
  margin-top: 24px;
  font-family: 'Poppins';
  @media ${mobile} {
    font-size: 1rem;
  }
`;

const Title = styled(Text)`
  color: #1c1c28;
  font-size: 48px;
  font-weight: 300;
  letter-spacing: -0.036em;
  line-height: 140%;
  margin: 0;
  font-family: 'Poppins';
  @media ${mobile} {
    font-size: 32px;
  }
`;

const TitleContainer = styled.div`
  padding: 72px 0;
  text-align: center;
`;

const TitleWrap = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 0 32px;
  width: 1204px;
  @media ${mobile} {
    padding: 0 16px;
    text-align: left;
  }
`;
